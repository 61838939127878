<template>
  <div class="container-fluid multiple-tabs news-container mt-3">
    <Form @submit="updateNews" v-if="!loading" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الأخبار' , path: '/all-news'}" current_page="تعديل الخبر"/>
        <div class="controls-btns d-flex gap-2 mb-3">
          <!--        <button class="btn btn-save">نشر وعودة للأرشيف</button>-->
          <button class="btn btn-publish" type="submit" @click="updateNews" :disabled="loading">حفظ</button>
          <button class="btn btn-save-draft">حفظ مسودة</button>
          <a target="_blank" :href="news.url" class="btn btn-preview" v-if="!news.is_draft">
            <i class="las la-external-link-alt"></i>
          </a>
        </div>
      </div>
      <el-card>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="الخبر" name="news">
            <NewsDetails :newsDetails="news" :contentError="contentError" :errors="errors" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="الصور" name="media">
            <MediaTab :prevPhotos="news.photos" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="محركات البحث" name="seo">
            <SEO :seoData="news" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
            <SocialMediaTab :socialData="news" @update="handleOnChange"/>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </Form>
    <Loader v-else/>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import NewsDetails from "./components/newsDetails";
import SEO from "@/views/news/components/SEO";
import SocialMediaTab from "@/views/news/components/SocialMediaTab";
import {Form} from "vee-validate";
import MediaTab from "@/views/news/components/MediaTab";
import {getSingleNews, updateNews} from "@/services/news";
import Loader from "@/components/Loader";
import {useToast} from "vue-toastification";
import {dateFormatter} from "../../utils/formatDate";

export default {
  name: "Edit News",
  data()
  {
    return {
      toast       : useToast(),
      activeTab   : "news",
      news        : {},
      hasError    : false,
      contentError: false,
      loading     : false
    }
  },
  components: {Loader, MediaTab, SocialMediaTab, SEO, Breadcrumb, NewsDetails, Form},
  mounted()
  {
    this.fetchNewsData()
  },
  methods: {
    handleOnChange(key, val)
    {
      console.log('changed')
      this.news = {
        ...this.news,
        [key]: val
      }
    },
    fetchNewsData()
    {
      this.loading = true
      getSingleNews(this.$route.params.news_id)
          .then(res => {
            this.loading            = false
            const {data}            = res;
            data.seo_title          = data.metas.seo_title;
            data.seo_description    = data.metas.seo_description;
            data.social_title       = data.metas.social_title;
            data.social_description = data.metas.social_description;
            data.social_image       = data.metas.social_image;
            this.news               = data;
          })
          .catch(() => {
            this.loading = false
          })
    },
    updateNews()
    {
      this.loading     = true;
      const mappedTags = this.news.tags.map(tag => tag.id || tag)
      updateNews(this.$route.params.news_id, {...this.news, tags: mappedTags})
          .then(res => {
            this.loading = false
            setTimeout(() => this.$router.push('/all-news'), 300)
            this.toast.success("تم تعديل الخبر بنجاح", {
              position: "top-center",
            });
            console.log(res)
          })
          .catch(() => {
            this.loading = false
          })
    }
  }
}
</script>


<style lang="scss">
.news-container {
  margin-top: 2rem;

  .btn-preview {
    background-color: #c9c9c9;
    min-width: 50px;
    font-size: 1.25rem;
  }

  .btn-preview:hover {
    background-color: #dedddd;
  }

  .el-tabs__nav {
    float: right;
  }

}
</style>
